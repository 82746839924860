#team {
  #intro {
    div.ant-typography {
      margin: 1em 0;
    }
  }

  #people {
    .person {
      margin: 0;
      padding: 3em 0;

      h3 {
        font-weight: bold;
      }

      a {
        display: block;
      }
    }
  }
  .white-background {
    background-color: white;
    clip-path: polygon(80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
  }
  .blue-background {
    background-color: #162760;
  }
  .boxed {
    max-width: 1150px;
    margin: auto;
    padding: 2em;
  }
  .white-text {
    color: white;
    max-width: 1000px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.86;
  }
  .ph-max {
    max-width: 392px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374058;
    @media only screen and (max-width: 650px) {
      max-width: unset;
    }
  }
  .partner-logo {
    margin: 25px 50px 25px 50px;
    max-width: 312px;
    max-height: 114px;
    @media only screen and (max-width: 650px) {
      max-width: 250px;
    }
    @media only screen and (max-width: 320px) {
      max-width: 200px;
    }
  }
  .ant-background {
    background-color: #f6f7fc;
  }
  .mb-50 {
    padding-bottom: 64px;
  }
  .apoios {
    padding: 20px 0 40px;
    .ant-col {
      text-align: center;
      .partner {
        padding: 2em 0;
        img {
          width: 20em;
          max-width: 90%;
        }
      }
    }
  }
  .partners {
    display: flex;
    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .ant-row {
    @media only screen and (max-width: 650px) {
      align-items: flex-start;
    }
  }
}
