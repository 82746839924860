#precautions {
  .boxed {
    max-width: 1150px;
    margin: auto;
    padding: 2em;
  }
  .measures-background {
    background: url('../../../assets/images/do-background.svg') center center
      no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .higiene {
    background-color: #162760;
    background: url('../../../assets/images/background-higiene.png') center
      center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .card-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    justify-content: flex-start;

    width: 100%;
    margin-bottom: 30px;

    &.do {
      height: 490px;
    }
    &.dont {
      height: 250px;
    }
    &.hygiene {
      height: 400px;
    }

    @media screen and (max-width: 1024px) {
      &.do {
        height: 600px;
      }
      &.dont {
        height: 300px;
      }
      &.hygiene {
        height: 450px;
      }
    }
    @media screen and (max-width: 768px) {
      &.do {
        height: auto;
      }
      &.dont {
        height: auto;
      }
      &.hygiene {
        height: auto;
      }
    }

    .card-wrapper {
      position: relative;
      width: 33%;
      padding: 20px 10px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .ant-card {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      box-shadow: 0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405),
        0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662),
        0px 16px 64px rgba(0, 0, 0, 0.05);
      border: none;
      border-left: 2px solid;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .do.ant-card {
      background-color: rgba(101, 178, 172, 0.05);
      border-left-color: #65b2ac;
    }

    .dont.ant-card {
      background-color: #f4eaf0;
      border-left-color: #e5000d;
    }
    
  }
  .ant-card-body{
    max-width: 389px;
  }
  .do-icon,
  .dont-do-icon {
    width: 48px;
    height: 48px;
    position: relative;
    top: -20px;
    left: -20px;
  }

  .do-title,
  .dont-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .do-title {
    color: #65b2ac;
  }

  .dont-title {
    color: #e5000d;
  }

  .home-quarentena {
    width: 90%;
    align-self: center;
    max-width: 1200px;
    object-fit: cover;
  }
  .row {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .row-wrap {
    display: flex;
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .col-wrap {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      flex-wrap: unset;
      flex-direction: column;
    }
  }
  .do-it {
    background-color: #e8f3f3;
    margin: 23px;
    flex: 1;
  }
  .dont-do {
    background-color: #f4eaf0;
    margin: 23px;
    flex: 1;
    height: 50%;
  }
  .pad-top-50 {
    padding-top: 50px;
  }
  .measure-icon {
    flex: 0 1 auto;
    height: 112px;
    width: 112px;
    padding-left: 15px;
  }
  .green-background {
    background-color: #e8f3f3;
  }
  .red-background {
    background-color: #f4eaf0;
  }
  .pad-card {
    padding: 36px 0 36px 0;
  }
  .transparent-background {
    color: white;
    background-color: transparent;
    opacity: 0.86;
  }
}
