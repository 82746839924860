#stats-page {
  position: relative;

  #map {
    width: 100vw;
    height: calc(100vh - 60px + 20px);
  }

  .control-panel {
    background: white;

    position: absolute;
    z-index: 9;
    top: 2em;
    left: 2em;

    width: 30em;
    height: auto;

    @media screen and (max-width: 768px) {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
    }

    padding: 2em;

    .box {
      width: 1em;
      height: 1em;
      display: inline-block;

      &.monitoring {
        background-color: rgba(33, 102, 172, 1);
      }
      &.in-risk {
        background-color: rgba(255, 201, 81, 1);
      }
      &.infected {
        background-color: rgba(255, 101, 101, 1);
      }
    }
  }
}
