
@font-face {
  font-family: 'Clan';
  src: url('./assets/fonts/Clan-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Clan';
  src: url('./assets/fonts/Clan-Bold.otf') format('opentype'), url('./assets/fonts/Clan-BoldSC.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Clan book';
  src: url('./assets/fonts/Clan-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clan booksc';
  src: url('./assets/fonts/Clan-BookSC.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clan newssc';
  src: url('./assets/fonts/Clan-NewsSC.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clan';
  src: url('./assets/fonts/Clan-ThinSC.otf') format('opentype'), url('./assets/fonts/Clan-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Clan ultrasc';
  src: url('./assets/fonts/Clan-UltraSC.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clanlf book';
  src: url('./assets/fonts/ClanLF-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clan ultra';
  src: url('./assets/fonts/Clan-Ultra.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clan news';
  src: url('./assets/fonts/Clan-News.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Clan';
  src: url('./assets/fonts/Clan-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

