#report-form {
  .ant-form-item-extra {
    margin-top: 10px;
  }

  .can-be-hidden {
    transition: all 0.5s ease-in-out;
    //   height: auto;
    min-height: 100px;
    overflow: hidden;
    opacity: 1;

    &.hide {
      min-height: 0;

      height: 0px;
      margin: 0px;
      padding: 0px;
      opacity: 0;
    }
  }

  .breathing-trouble-rate {
    // background-color: blue;
    .ant-radio-button-wrapper {
      span:not(.ant-radio-button) {
        img {
          width: 1.5em;
          display: block;
          margin: 0 0.5em;
          margin-top: 6px;
        }
      }

      &.ant-radio-button-wrapper-checked {
        img {
          filter: invert(1);
        }
      }
    }
  }
}

#emoji-table-explainer-container {
  img {
    width: 1.5em;
    display: block;
    margin: 0 0.5em;
    margin-top: 6px;
  }
}
