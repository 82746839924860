.main-icon-content-element {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin: 1em 0;

  > .icon {
    width: 6em;
    height: 6em;
    flex: 0 0 auto;
    margin-bottom: 2.5em;
  }
}

#home-page {
  // typography

  h2,
  h3 {
    font-weight: bold;
  }

  // titles
  h2 {
    margin: 1em 0 1em 0;
  }
  h3 {
    margin: 0 0 1em 0;
  }

  // paragraph
  div.ant-typography {
    margin: 0em 0 1em 0;
  }

  // SECTIONS
  // default rules to all sections
  section {
    padding: 4em 0;

    .ant-col.title-holder {
      margin-bottom: 3em;
    }
  }

  // SECTION - HERO
  section#hero {
    background: url('../../../assets/images/herobanner.svg') no-repeat center center;
    background-size: cover;

    .ant-card {
      max-width: 536px;
      background-color: transparent;
      text-align: left;
    }

    // ensure we can see part of the image
    min-height: 38em;
  }

  // SECTION - HOW IT WORKS
  section#how-it-works {
    .step {
      @extend .main-icon-content-element;
    }
  }

  // SECTION - FEATURES
  section#features {
    background-color: #162760;
    clip-path: polygon(80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);

    // all titles and paragraphs:
    .ant-typography {
      color: white;
      opacity: 0.86;
    }

    .feature {
      @extend .main-icon-content-element;
    }
  }

  // SECTION - PARTNERS
  section#partners {
    background-color: white;

    // all titles and paragraphs:
    .ant-typography {
      color: #374058;
    }

    .ant-btn {
      margin-top: 2em;
    }

    .ant-col {
      margin: 2em 0;
    }

    .title {
      text-align: center;
    }
    .logos {
      .ant-col {
        text-align: center;
        .partner {
          padding: 2em 0;
          img {
            width: 20em;
            max-width: 90%;
          }
        }
      }
    }
  }

  section#news {
    background-color: #d82c57;
    h1.ant-typography {
      color: white;
    }
    .slick-slide {
      padding: 20px;
      .ant-typography {
        margin-top: 15px;
        font-size: 20px;
        color: #374058;
        margin-bottom: 0;
      }
    }
    .ant-card {
      .ant-typography {
        background-color: white;
        color: #374058;
      }
    }
    .ant-carousel {
      .slick-prev {
        width: 50px;
        height: 50px;
        background: url('../../../assets/icons/previousArrow.png');
        background-size: cover;
      }
      .slick-next {
        width: 50px;
        height: 50px;
        background: url('../../../assets/icons/nextArrow.png');
        background-size: cover;
      }
    }
    .news-data {
      text-align: right;
      font-size: 18px;
      color: #646c73;
    }
  }

  // SECTION - YOUR DATA IS SAFE
  section#your-data-is-safe {
    .ant-col.icon {
      text-align: center;
    }

    .ant-col {
      margin: 2em 0;
    }
  }

  // SECTION - BOTTOM CTA
  section#bottom-cta {
    background: url('../../../assets/images/quarentena.png') center center
      no-repeat;
    background-size: cover;
    clip-path: polygon(80% 0, 100% 40%, 100% 100%, 0 100%, 0 0);

    // add some space to the button
    .ant-btn {
      margin-top: 2em;
    }

    // ensure we can see part of the image
    min-height: 38em;
  }
}
