#account-profile {
  .alert {
    margin: 2em 0;
  }

  .delete-account {
    margin-top: 2em;

    .ant-typography {
      margin-bottom: 1em;
    }
  }
}

.delete-profile-modal {
  .ant-typography {
    margin-bottom: 1em;
  }

  .ant-btn-primary {
    float: right;
    margin-left: 1em;
  }
}
