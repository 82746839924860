// NOTE: THIS `SCSS` FILE IS ONLY FOR GENERAL PURPOSE CLASSES
// PLEASE MAKE USE OF THE INDIVIDUAL `SCSS` FILES PER PAGE FOR
// PAGE SPECIFIC CSS RULES

$main: #374058;
$secondary: #90daef;

body {
  margin: 0;
  font-family: Clan, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  background-color: white;
}
.ant-layout-content{
  background-color: #F6F7FC;
}
.layout-container {
  position: relative;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;

  section {
    min-width: 100%;
    padding: 2em 0;
  }
}

.m-10 {
  margin: 10px 0 10px 0;
}

.table-container {
  width: 500px;
}

.ant-tooltip-inner {
  min-width: 515px !important;
}
.ant-checkbox-wrapper {
  width: 450px;
}
.site-layout-content {
  padding: 30px;
  min-height: calc(100vh - 65px);
}

.site-footer-socialmedia {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.site-footer-socialmedia div {
  margin: 5px;
}

.ant-form-item-has-error .ant-radio-group .ant-radio-button-wrapper {
  border-color: #f5222d;
}
.ant-form-item-has-error .ant-picker {
  border-color: #f5222d;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1.33em;
}
h5 {
  font-size: 0.83em;
}
h6 {
  font-size: 0.67em;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography {
  display: inline-block;
  color: $main;
  position: relative;

  // text-decoration-line: underline;
  // text-decoration-color: $secondary;
  // text-decoration-style: solid;

  // &::after {
  //   position: absolute;
  //   content: "";
  //   height: 0.3em;
  //   right: 0;
  //   bottom: -0.3em;
  //   left: 0;
  //   background: $secondary;
  // }
}

.ant-menu-horizontal > .ant-menu-item-selected {
  color: $secondary;
  border-color: $secondary;
  border-width: 0.3em;
  > a {
    color: $main;
  }
}

.section > p,
.section-body {
  color: $main;
  font-size: 18px;
  font-style: normal;
  font-weight: thin;
}
.section-text {
  font-size: 15px;
  line-height: 25px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
}
.partners {
  display: flex;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .partners {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 576px) {
  .hide-mobile {
    display: none;
  }
}

.ant-table-content {
  overflow: auto;
}
@media only screen and (max-width: 400px) {
  .ant-form-item-required {
    padding-top: 5px;
  }
  .site-layout-content {
    padding: 5px;
  }
}

#header {
  display: flex;
  align-items: center;
  padding: 0;
  .boxed{
    max-width: 1150px;
  }
  #menu {
    
    height: 65px;
    line-height: 65px;
    flex: auto;

    .anticon {
      vertical-align: middle;
    }

    > .ant-menu-item,
    > .ant-menu-submenu {
      float: right;
    }
  }
}
.red-link, .underline-red {
  color: #D82C57;
  font-weight: bold;
}
.underline-red{
  text-decoration: underline;
}
.site-footer-pt2020{
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
  max-width: 800px;
  max-height: 100px;
}
.pt2020-banner{
  max-width: 768px;
  object-fit: contain;
}
.pt2020-title{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
}
.pt2020-link{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  text-align: center;
}
// NOTE: THIS `SCSS` FILE IS ONLY FOR GENERAL PURPOSE CLASSES
// PLEASE MAKE USE OF THE INDIVIDUAL `SCSS` FILES PER PAGE FOR
// PAGE SPECIFIC CSS RULES
