$color-monitor: rgba(241, 204, 38, 0.7);
$color-suspect: rgba(243, 51, 60, 0.7);

#patient-dashboard {
  div.ant-typography:not(.no-margins) {
    margin-bottom: 2em;
  }

  .ant-row > .ant-col > .ant-card {
    height: 100%;
  }

  #history-widget {
    .ant-card-body {
      padding: 0;
    }
  }

  #monitor-data-widget {
    width: 100%;
    card > .stat {
      text-align: center;
    }
  }

  #health-analysis-widget {
    .ant-progress {
      margin: 1.5em;
    }
    .ant-typography {
      text-align: center;
    }

    .disclaimer {
      color: #aaa;
    }
  }

  #social-share-widget {
  }

  #my-zone-widget {
    #colorDisplay {
      margin: 45px 0;
      width: 100%;
      position: relative;
      text-align: center;

      .colorCircle {
        display: inline-block;
        border-radius: 50%;
        font-size: 28px;
        color: #0c0d44;

        &.bigger {
          height: 150px;
          width: 150px;
          line-height: 150px;
          margin-right: 50px;
        }
        &.smaller {
          height: 75px;
          width: 75px;
          line-height: 75px;
          position: absolute;
          bottom: -25px;
          right: calc(50% - 85px);
        }
      }
    }
    .coloredSquare {
      height: 20px;
      width: 20px;
      float: left;
      margin-right: 10px;
    }
    .colorCircle,
    .coloredSquare {
      &.monitor {
        background-color: $color-monitor;
      }
      &.suspect {
        background-color: $color-suspect;
      }
    }

    .infoNoCases {
      color: #0c0d44;
    }
  }
}
